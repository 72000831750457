import React, { createContext, useCallback, useContext, useState } from 'react'
import useBlockBodyScroll from '~/hooks/useBlockBodyScroll'
import { showModal } from '~/utils/analytics'

export interface ModalsContextInterface {
  // this is supposed to be a mounted React component, ie <ModalTest />
  currentModal: any
  // these are all modals that are currently active. this allows us to
  // load additional modals while other modals are already showing.
  modalsStack: any[]
  // modal arg is a mounted React component, ie <ModalTest />
  show: (modalName: string, modal: any) => void
  // modal arg is a mounted React component, ie <ModalTest />
  replace: (modalName: string, modal: any) => void
  // clears the entire modal stack
  clear: () => void
  // hides the top modal
  hide: () => void
  fadeIn: boolean
  setFadeIn: (arg0: boolean) => void
}

export const ModalsContext = createContext<ModalsContextInterface | null>(null)

interface Props {
  children?: any
}

export const ModalsContextProvider: React.FC<Props> = ({ children }) => {
  const [currentModal, setCurrentModal] = useState(null)
  const [modalsStack, setModalsStack] = useState<any[]>([])
  const [fadeIn, setFadeIn] = useState(false)
  const hasModals = modalsStack.length > 0

  useBlockBodyScroll(hasModals)

  const replace = useCallback(
    (modalName: string, modal: any) => {
      showModal(modalName)
      modalsStack.pop()
      modalsStack.push(modal)
      setModalsStack(modalsStack)
      setCurrentModal(modal)
    },
    [modalsStack]
  )

  const show = useCallback(
    (modalName: string, modal: any) => {
      showModal(modalName)
      modalsStack.push(modal)
      setModalsStack(modalsStack)
      setCurrentModal(modal)
    },
    [modalsStack]
  )

  const hide = useCallback(() => {
    setFadeIn(false)
    window.setTimeout(() => {
      modalsStack.pop()
      setModalsStack(modalsStack)
      if (modalsStack.length !== 0) {
        setCurrentModal(modalsStack[modalsStack.length - 1])
      } else {
        setCurrentModal(null)
      }
    }, 200)
  }, [modalsStack])

  const clear = useCallback(() => {
    setFadeIn(false)
    window.setTimeout(() => {
      setModalsStack([])
      setCurrentModal(null)
    }, 200)
  }, [])

  const ctx = {
    currentModal,
    modalsStack,
    replace,
    show,
    hide,
    clear,
    setFadeIn,
    fadeIn,
  }

  return <ModalsContext.Provider value={ctx}>{children}</ModalsContext.Provider>
}

const Modals = () => {
  const modalsCtx = useContext(ModalsContext)

  if (!modalsCtx) {
    return null
  }

  if (modalsCtx.currentModal) {
    const modal = React.cloneElement(modalsCtx.currentModal)
    return modal
  }

  return null
}

export default Modals
